import keymaster from "./4.jpg";
import iAwait from "./1.jpg";
import bones from "./5.jpg";
import solemn from "./8.jpg";
import redchamber from "./9.jpg";
import home from "./10.jpg";
import misery from "./12.jpg";
import anHD from "./1897.jpg";
import underTheSun from "./2897.jpg";
import feelWarmth from "./1178.jpg";
import canIGetSide from "./0731.jpg";
import canIGet from "./0733.jpg";
import canIGetDetail1 from "./0757.jpg";
import canIGetDetail2 from "./0755.jpg";
import brain from "./1299.jpg";
import brainBurned from "./1501.jpg";
import caged from "./1512.jpg";
import cagedDetail from "./1514.jpg";
import smile from "./1528.jpg";
import smileDetail from "./1532.jpg";
import enlightenment from "./1a.jpg";
import enlightenmentDetail from "./1b.jpg";
import cage from "./DSCF1778.jpg";

export default [
  { src: cage, alt: "cage" },
  {
    src: iAwait,
    alt: "i await the clouds to break",
    id: "i-await-the-clouds-to-break",
  },
  { src: bones, alt: "bones" },
  { src: solemn, alt: "solemn" },
  { src: redchamber, alt: "red chamber" },
  { src: home, alt: "home" },
  { src: misery, alt: "misery" },
  { src: keymaster, alt: "key master" },
  { src: caged, alt: "caged" },
  { src: cagedDetail, alt: "caged detail" },
  { src: smile, alt: "smile" },
  { src: smileDetail, alt: "smile detail" },
  {
    src: anHD,
    alt: "an hd240430 to call my own",
    id: "an-hd240430-to-call-my-own",
  },
  { src: underTheSun, alt: "under the sun" },
  { src: feelWarmth, alt: "i feel your warmth" },
  { src: brain, alt: "brain" },
  { src: brainBurned, alt: "brain burned" },
  { src: enlightenment, alt: "enlightenment" },
  { src: enlightenmentDetail, alt: "enlightenment detail" },
  {
    src: canIGetSide,
    alt: "can I get 1g in the chat",
    id: "can-i-get-1g-in-the-chat",
  },
  { src: canIGet, alt: "can I get 1g in the chat" },
  { src: canIGetDetail1, alt: "can I get 1g in the chat detail" },
  { src: canIGetDetail2, alt: "can I get 1g in the chat detail" },
];
