import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import "normalize.css";

// parcel hot module replacement
// https://parceljs.org/hmr.html
if (module.hot) module.hot.accept();

ReactDOM.render(<App />, document.querySelector("#app"));
