import React from "react";
import styled from "styled-components";

const Container = styled.nav`
  position: fixed;
  right: 0;
  text-align: right;
  ul {
    color: #ccc;
    list-style-type: none;
    margin: 1em;
    padding: 0;
    a {
      color: #ccc;
      &:hover {
        color: black;
      }
    }
  }
`;

export const Nav = () => {
  return (
    <Container>
      <ul>
        <a href="#i-await-the-clouds-to-break">
          <li>I Await the Clouds to Break</li>
        </a>
        <a href="#an-hd240430-to-call-my-own">
          <li>An HD240430 to call my own</li>
        </a>
        <a href="#can-i-get-1g-in-the-chat">
          <li>Can I get 1g in the chat?</li>
        </a>
        <li>~</li>
        <a href="https://goodenoughfor.me" target="_blank">
          <li>Good Enough</li>
        </a>
        <li>~</li>
        <a href="mailto:steffenssornpao@gmail.com">
          <li>Contact</li>
        </a>
      </ul>
    </Container>
  );
};
