import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import images from "./assets/images";
import { Nav } from "./components";

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ darkMode }) => (darkMode ? "black" : "default")};
    transition: background 1s;
    width: 100vw;
  }
  a {
    color: black;
    text-decoration: none;
  }
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  opacity: ${({loading}) => loading ? 0 : 1};
  transition: opacity 0.5s;
  width: 100%;
  img {
    margin: 0 auto;
    width: 100%;
  }
`;

const DarkModeButton = styled.div`
  bottom: 0;
  color: #ccc;
  cursor: pointer;
  font-size: 2em;
  line-height: 21px;
  margin: 1rem;
  position: fixed;
  @media (max-width: 500px) {
    pointer-events: none;
  }
`;

export default () => {
  const [darkMode, toggleDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  return (
    <>
      <GlobalStyle darkMode={darkMode} />
      <Nav />
      <Container loading={loading}>
        {images.map(({ alt, id, src }, i) => (
            <img
              alt={alt}
              id={id}
              key={`img-${i}`}
              loading="lazy"
              onLoad={() => i === 0 ? setLoading(false) : null} 
              src={src}
            />
        ))}
      </Container>
      <DarkModeButton
        onClick={() => toggleDarkMode(!darkMode)}
        darkMode={darkMode}
      >
        ⚘
      </DarkModeButton>
    </>
  );
};
